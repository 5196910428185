import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  clickedSlide?: number
  images: ImageProps[]
  isOpen?: boolean
}

export const Gallery = memo(function Gallery({
  clickedSlide,
  images,
  isOpen,
}: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [currentCategory, setCurrentCategory] = React.useState<any>()
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1200,
    },
    drag: false,
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      // @ts-ignore
      setCurrentCategory(images[s.track.details.rel].category)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    if (clickedSlide !== undefined) {
      galleryRef.current?.moveToIdx(clickedSlide, false, { duration: 0 })
    }

    if (isOpen) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 37:
            galleryRef.current?.prev()
            break
          case 39:
            galleryRef.current?.next()
            break
        }
      })
    }
  }, [clickedSlide, currentSlide, galleryRef, sliderRef])

  return (
    <Container>
      <Slider ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              <Image
                alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                {...(loaded[index] || nextLoaded[index] ? item : null)}
              />

              <Spinner />
            </Inner>
          </Slide>
        ))}
      </Slider>

      <Info dial={4} row space="between">
        <Category>{currentCategory}</Category>

        {images && images.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) => e.stopPropagation() || galleryRef.current?.prev()}
              variant="invert"
            />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || galleryRef.current?.next()}
              variant="invert"
            />
          </Arrows>
        ) : null}

        <Counter>{`${currentSlide + 1}/${images.length}`}</Counter>
      </Info>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 10rem;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 1)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    &:before {
      height: 6.25rem;
    }
  }
`

const Slider = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Info = styled(FlexBox)`
  width: 100%;
  height: 10rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 0 5vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;

  @media (max-width: 1199px) {
    height: 6.25rem;
    padding: 0 1.875rem;
  }
`

const Category = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.5rem;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1199px) {
    top: auto;
    left: auto;
    right: 1.125rem;
    transform: none;
  }
`

const Counter = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 0.875rem;
`
